import { type ApiResponse } from "../../../types";
import {
  type CreateWorkspaceRequest,
  type CreateWorkspaceResponse,
  type UpdateWorkspaceRequest,
} from "./workspace.dao";
import CSTPlatform from "../../../CSTPlatform";
import { type BaseWorkspace } from "./workspace.types";

const WorkspaceApi = {
  async create(
    workspace: CreateWorkspaceRequest
  ): Promise<ApiResponse<CreateWorkspaceResponse>> {
    const response = await CSTPlatform.getInstance().post(
      "/organizations/me/workflows",
      workspace
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async get(id: string): Promise<ApiResponse<BaseWorkspace>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/workflows/${id}`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async update(
    id: string,
    options: UpdateWorkspaceRequest
  ): Promise<ApiResponse<UpdateWorkspaceRequest>> {
    const response = await CSTPlatform.getInstance().put(
      `/organizations/me/workflows/${id}`,
      {
        name: options.name,
        description: options.description,
      }
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async updateAttributes(
    id: string,
    attributes: object
  ): Promise<ApiResponse<object>> {
    const response = await CSTPlatform.getInstance().put(
      `/organizations/me/workflows/${id}/attributes`,
      attributes
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async list(): Promise<ApiResponse<BaseWorkspace[]>> {
    const response = await CSTPlatform.getInstance().get(
      `/organizations/me/workflows/list`
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default WorkspaceApi;
