import DashboardLayout from "../../../base/layouts/dashboard/DashboardLayout";
import Root from "../../../base/routes/paths";
import { useParams } from "react-router-dom";
import { kebabCaseToTitleCase } from "../../../base/utils/formatText";
import useWorkspace from "../../../base/hooks/useWorkspace";
import { useEffect } from "react";
import LoadingScreen from "../../../base/components/LoadingScreen";
import HelpDialogButton from "../components/help/HelpDialogButton";

export default function ClientLayout() {
  const { workspaceId } = useParams();
  const workflow = useWorkspace();

  useEffect(() => {
    if (workspaceId) {
      void workflow.select(workspaceId);
    }
  }, [workspaceId, workflow.workspaces]);

  if (!workflow.selected) {
    return <LoadingScreen isActive />;
  }

  return (
    <>
      <HelpDialogButton />
      <DashboardLayout
        navigationConfig={[
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).Analytics.Explore
              .pathname,
            label: "Explore",
            icon: "MdExplore",
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).Analytics.pathname,
            label: kebabCaseToTitleCase(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Root.Studio.Call.Get(workspaceId!).Analytics.name
            ),
            icon: "MdAnalytics",
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).Browser.pathname,
            label: kebabCaseToTitleCase(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Root.Studio.Call.Get(workspaceId!).Browser.name
            ),
            icon: "MdCall",
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).Integrations.pathname,
            label: kebabCaseToTitleCase(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Root.Studio.Call.Get(workspaceId!).Integrations.name
            ),
            icon: "TiFlowChildren",
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).Automations.pathname,
            label: kebabCaseToTitleCase(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Root.Studio.Call.Get(workspaceId!).Automations.name
            ),
            icon: "RiFlowChart",
          },
          {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            pathname: Root.Studio.Call.Get(workspaceId!).FormBuilder.pathname,
            label: kebabCaseToTitleCase(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              Root.Studio.Call.Get(workspaceId!).FormBuilder.name
            ),
            icon: "MdPoll",
          },
        ]}
      />
    </>
  );
}
