const PATHS = {
  name: "call",
  pathname: "studios/call",
  Get: (tokenProject: string) => ({
    name: `${tokenProject}`,
    pathname: `studios/call/${tokenProject}`,
    Analytics: {
      name: "analytics",
      pathname: `/studios/call/${tokenProject}/analytics`,
      Explore: {
        name: "explore",
        pathname: `/studios/call/${tokenProject}/analytics/explore`,
      },
      Sample: {
        name: "samples",
        pathname: `/studios/call/${tokenProject}/analytics/samples`,
        Get: (tokenSample: string) => ({
          name: `${tokenSample}`,
          pathname: `/studios/call/${tokenProject}/analytics/samples/${tokenSample}`,
          Preview: {
            name: "preview",
            pathname: `/studios/call/${tokenProject}/analytics/samples/${tokenSample}/preview`,
          },
        }),
      },
    },
    Automations: {
      name: "automations",
      pathname: `/studios/call/${tokenProject}/automations`,
      Sample: {
        name: "samples",
        pathname: `/studios/call/${tokenProject}/automations/samples`,
        Get: (tokenSample: string) => ({
          name: `${tokenSample}`,
          pathname: `/studios/call/${tokenProject}/automations/samples/${tokenSample}`,
          Preview: {
            name: "preview",
            pathname: `/studios/call/${tokenProject}/automations/samples/${tokenSample}/preview`,
          },
        }),
      },
    },
    RealTime: {
      // Duplicate of Analytics renamed to RealTime
      name: "real-time",
      pathname: `/studios/call/${tokenProject}/real-time`,
      Sample: {
        name: "samples",
        pathname: `/studios/call/${tokenProject}/real-time/samples`,
        Get: (tokenSample: string) => ({
          name: `${tokenSample}`,
          pathname: `/studios/call/${tokenProject}/real-time/samples/${tokenSample}`,
          Preview: {
            name: "preview",
            pathname: `/studios/call/${tokenProject}/real-time/samples/${tokenSample}/preview`,
          },
        }),
      },
    },
    FormBuilder: {
      name: "form-builder",
      pathname: `/studios/call/${tokenProject}/form-builder`,
    },
    AISearch: {
      name: "ai-search",
      pathname: `/studios/call/${tokenProject}/ai-search`,
    },
    Browser: {
      name: "browser",
      pathname: `/studios/call/${tokenProject}/browser`,
      Get: (tokenBrowser: string) => ({
        name: `${tokenBrowser}`,
        pathname: `/studios/call/${tokenProject}/browser/${tokenBrowser}`,
      }),
    },
    Integrations: {
      name: "integrations",
      pathname: `/studios/call/${tokenProject}/integrations`,
      Get: (tokenBrowser: string) => ({
        name: `${tokenBrowser}`,
        pathname: `/studios/call/${tokenProject}/integrations/${tokenBrowser}`,
      }),
    },
  }),
};

export default PATHS;
