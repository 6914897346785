import CSTPlatform from "../../../CSTPlatform";
import { type ApiResponse } from "../../../types";
import {
  type GetAudioWavUrlRequest,
  type GetAudioWavUrlResponse,
  type RetrieveCallsRequest,
  type RetrieveCallsResponse,
} from "./call.dto";

const CallApi = {
  async retrieve(
    workspaceId: string,
    request: RetrieveCallsRequest
  ): Promise<ApiResponse<RetrieveCallsResponse>> {
    const response = await CSTPlatform.getInstance().post(
      `/workflows/${workspaceId}/calls/retrieve`,
      request
    );
    return {
      payload: response.data,
      statusCode: response.status,
      message: response.statusText,
    };
  },
  async getAudioWavUrl(
    workspaceId: string,
    request: GetAudioWavUrlRequest
  ): Promise<ApiResponse<GetAudioWavUrlResponse>> {
    const response = await CSTPlatform.getInstance().get(
      `/workflows/${workspaceId}/calls/${request.callId}/audio/wav`
    );
    return {
      payload: {
        url: response.data,
      },
      statusCode: response.status,
      message: response.statusText,
    };
  },
};

export default CallApi;
